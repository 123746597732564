<template>
  <div class="slider" v-if="testimonials">
    <div class="container">
      <div class="content">
        <div class="slider-navigation" :class="{'active': isActive}">
          <h4 class="title">{{ title }}</h4>
          <div class="counter-position">
            <p class="slide-number">{{ comment }}</p>
            <p class="all-comments">/ {{ allComment }}</p>
          </div>
        </div>
        <vueper-slides
          @ready="countComment()"
          @slide="countComment()"
          class="no-shadow"
          slide-multiple
          :visible-slides="4"
          :dragging-distance="450"
          :slide-ratio="1 / 4"
          :gap="0"
          :breakpoints="{
            1400: {
              visibleSlides: 3,
              draggingDistance: 300,
            },
            800: {
              visibleSlides: 1,
              slideMultiple: 2,
              draggingDistance: 50,
            },
          }"
        >
          <!-- Arrow right -->
          <template #arrow-left>
            <svg
              width="40"
              height="12"
              viewBox="0 0 40 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="3.16016"
                y="4.88174"
                width="36.8406"
                height="2.23629"
                rx="1.11815"
                fill="#D1D4D7"
              />
              <rect
                width="11.1682"
                height="2.23541"
                rx="1.1177"
                transform="matrix(0.865683 0.500593 -0.499408 0.866367 1.11621 4.47354)"
                fill="#D1D4D7"
              />
              <rect
                width="11.1682"
                height="2.23541"
                rx="1.1177"
                transform="matrix(0.865683 -0.500593 0.499408 0.866367 0 5.59073)"
                fill="#D1D4D7"
              />
            </svg>
          </template>

          <vueper-slide
            v-for="(slide, i) in testimonials"
            :arrows="true"
            :key="i"
            :content="'&#9473;' + slide.title"
            :title="slide.description"
          >
          </vueper-slide>

          <!-- Arrow right -->
          <template #arrow-right>
            <svg
              width="40"
              height="12"
              viewBox="0 0 40 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="4.8837"
                width="36.8405"
                height="2.23629"
                rx="1.11815"
                fill="#D1D4D7"
              />
              <rect
                width="11.1682"
                height="2.23541"
                rx="1.1177"
                transform="matrix(0.865682 0.500594 -0.499406 0.866368 30.332 0.00088501)"
                fill="#D1D4D7"
              />
              <rect
                width="11.1682"
                height="2.23541"
                rx="1.1177"
                transform="matrix(0.865682 -0.500594 0.499406 0.866368 29.2148 10.0634)"
                fill="#D1D4D7"
              />
            </svg>
          </template>
        </vueper-slides>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css"; // This imports vueper slides library CSS that is initally missing for some reason

const { t } = useTranslation(['shared']);

const props = defineProps({
  active: Number,
  title: String,
  testimonials: Array,
});

const store = useStore();

const isMobile = computed(() => store.state.device.isMobile);
const comment = ref(0);
const allComment = ref(0);

const isActive = computed(() => {
  if (isMobile.value) return props.active >= 6;
  return props.active === 6;
});

function countComment() {
  setTimeout(() => {
    const activeCommentElement = document.querySelector(".vueperslides__bullet--active span");
    const totalCommentsElement = document.querySelector(".vueperslides__bullets .vueperslides__bullet:last-child span");

    if (activeCommentElement && totalCommentsElement) {
      let activeComment = activeCommentElement.textContent;
      let totalComments = totalCommentsElement.textContent;

      comment.value = activeComment <= 9 ? `0${activeComment}` : activeComment;
      allComment.value = totalComments <= 9 ? `0${totalComments}` : totalComments;
    }
  }, 1000);
}

watch(props.testimonials, (newVal) => {
  if (newVal) {
    countComment();
  }
}, { deep: true });

onMounted(() => {
  countComment();
});
</script>
