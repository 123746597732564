export const GET_DISTRIBUTOR_BY_UUID = () => {
    return `
query DistributorByUuid($uuid: String!) {
    distributorByUuid(input: { uuid: $uuid }) {
      	distributor {
        	id
        	uuid
        	name
        	language
        	linkEvo
        	linkEvoObd
    	}
      		result {
        		status
        		message
      		}
    	}
  	}
`;
};

export const GET_CAMPAIGN_BY_COUNTRY_CODE = (country, lang, env) => {
	return `
	query CampaignByCountryCode {
    campaignByCountryCode(
        input: { countryCode: "${country}", environment: ${env}, language: ${lang} }
    ) {
        campaign {
            id
            ribbonDesktop
            ribbonMobile
            ribbonTagDesktop
            ribbonTagMobile
            ribbonUrl
            menuTag
            footerTag
            menuTagAndFooterTagUrl
            adaptersPageEvoTag
            adaptersPageEvoTagUrl
            adaptersPageCaristaObdTag
            adaptersPageCaristaObdTagUrl
            appAndPricingPagesEvoTag
            appAndPricingPagesEvoTagUrl
            appAndPricingPagesCaristaObdTag
            appAndPricingPagesCaristaObdTagUrl
            promoPriceEvo
            promoPriceCaristaObd
            popUpTagDesktop
            popUpTagMobile
            popUpTitleDesktop
            popUpTitleMobile
            popUpSubtitleDesktop
            popUpSubtitleMobile
            popUpButtonDesktop
            popUpButtonMobile
            popUpButtonUrl
            countryId
            endTime
            remainingTime
            remainingTimeSeconds
            active
            ribbonAnimation
            environment
        }
        result {
            status
            message
            messageBag {
                messages
            }
        }
    }
}`;
}
