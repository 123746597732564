<template>
    <div v-if="adapter" class="adapter-page">
        <BreadCrumb class="text-white"></BreadCrumb>
        <div class="container-wrapper-evo page">
            <div class="container container-hero">
                <h1 class="section-title">{{ adapter.headings.h1[0] }}</h1>
                <h2 class="section-subtitle">
                    {{ adapter.headings.h2[0] }}
                </h2>
                <a
                    v-if="isMobile && tagEvo && $appConfig.ENABLE_CAMPAIGNS"
                    class="sale-tag-container"
                    :href="tagEvoUrl"
                    target="”_blank"
                >
                    <SaleTag :text="tagEvo" />
                </a>
                <img
                    :src="adapter.images[0].url"
                    :alt="adapter?.images[0]?.imageMeta?.metaAltDescription"
                    :title="adapter?.images[0]?.imageMeta?.metaTitle"
                    loading="lazy"
                />
                <div class="container-hero__text">
                    <a
                        v-if="
                            !isMobile && tagEvo && $appConfig.ENABLE_CAMPAIGNS
                        "
                        class="sale-tag-container-desktop"
                        :href="tagEvoUrl"
                        target="”_blank"
                    >
                        <SaleTag :text="tagEvo" />
                    </a>
                    <p>
                        {{ t('page-adapter_adapter-description-1') }}
                    </p>
                    <p>
                        {{ t('page-adapter_adapter-description-2') }}
                    </p>
                    <p v-if="isInUK && $appConfig.saleEnabled">
                        {{
                            t(
                                'Order NOW for FREE Carista App Diagnostics. Lifetime access is included. Terms apply.'
                            )
                        }}
                    </p>
                    <div class="price-container">
                        <span class="price"
                            >{{ prices?.currency
                            }}{{
                                campaignPriceEvo && $appConfig.ENABLE_CAMPAIGNS
                                    ? campaignPriceEvo
                                    : prices?.evoAdapterPrice
                            }}</span
                        >
                        <s
                            v-if="
                                campaignPriceEvo && $appConfig.ENABLE_CAMPAIGNS
                            "
                            id="original-price"
                            >{{ prices.currency + prices?.evoAdapterPrice }}</s
                        >
                    </div>
                    <button
                        @click="openVendorModal('evo')"
                        class="btn-feedback-outlined lighter"
                    >
                        {{ t('page-adapter_buy-now') }} <ArrowRightIcon />
                    </button>
                </div>
            </div>
        </div>
        <div class="container-wrapper page">
            <div class="container container-hero">
                <h2 class="section-title">{{ adapter.headings.h2[1] }}</h2>
                <h2 class="section-subtitle">
                    {{ adapter.headings.h2[2] }}
                </h2>
                <a
                    v-if="isMobile && tagObd && $appConfig.ENABLE_CAMPAIGNS"
                    class="sale-tag-container"
                    :href="tagObdUrl"
                    target="”_blank"
                >
                    <SaleTag :text="tagObd" />
                </a>
                <img
                    :src="adapter.images[1].url"
                    :alt="adapter?.images[1]?.imageMeta?.metaAltDescription"
                    :title="adapter?.images[1]?.imageMeta?.metaTitle"
                    loading="lazy"
                />
                <div class="container-hero__text">
                    <a
                        v-if="
                            !isMobile && tagObd && $appConfig.ENABLE_CAMPAIGNS
                        "
                        class="sale-tag-container-desktop"
                        :href="tagObdUrl"
                        target="”_blank"
                    >
                        <SaleTag :text="tagObd" />
                    </a>
                    <p>
                        {{ t('page-adapter_adapter-description-3') }}
                    </p>
                    <div class="price-container obd">
                        <span class="price"
                            >{{ prices?.currency
                            }}{{
                                campaignPriceObd && $appConfig.ENABLE_CAMPAIGNS
                                    ? campaignPriceObd
                                    : prices?.adapterPrice
                            }}</span
                        >
                        <s
                            v-if="
                                campaignPriceObd && $appConfig.ENABLE_CAMPAIGNS
                            "
                            id="original-price"
                            >{{ prices.currency + prices?.adapterPrice }}</s
                        >
                    </div>
                    <button
                        @click="openVendorModal('obd')"
                        class="btn-feedback-outlined lighter"
                    >
                        {{ t('page-adapter_buy-now') }} <ArrowRightIcon />
                    </button>
                </div>
            </div>
        </div>
        <SupportedBrands />
        <HowItWorks
            id="adapterPageHowItWorks"
            :active="active"
            :activeStep="activeStep"
            @changeActiveStep="changeActiveStep"
        />
        <AppPricing id="appPricing" />
        <FeatureHighlights />
        <UserRatings />
        <VueperSlider
            :title="adapter?.headings?.h4[0]"
            :testimonials="adapterTestimonials"
            :active="6"
            :static="1"
        />
        <Faq
            :active="7"
            :static="true"
            :faqList="adapterFaq"
            :title="adapter?.headings?.h4[1]"
        />
        <Embark
            :title="[
                adapter.headings?.h4[2],
                adapter.headings?.h4[3],
                adapter.headings?.h4[4],
            ]"
            :btnSubtitle="t('shared_check-what-we-support-for-your-car')"
            fbEventParam="adapter page"
        />
    </div>
</template>

<script setup>
import {
    computed,
    ref,
    onMounted,
    onBeforeMount,
    onBeforeUnmount,
    watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import SupportedBrands from '../components/adapter/SupportedBrands';
import BreadCrumb from '../components/breadcrumb/BreadCrumb';
import HowItWorks from '../components/HowItWorks';
import AppPricing from '../components/adapter/AppPricing';
import VueperSlider from '../components/VueperSlider';
import Faq from '../components/Faq';
import Embark from '../components/Embark';
import ArrowRightIcon from '../assets/icons/supportedCars/ArrowRightIcon';
import {
    createMetaInfo,
    facebookGoogleAnalitycs,
    mapLangFullNameToCode,
} from '@/utils/helpers';
import { useTranslation } from 'i18next-vue';
import { useHead } from '@unhead/vue';
import SaleTag from '@/components/SaleTag.vue';
import FeatureHighlights from '../components/FeatureHighlights.vue';
import UserRatings from '../components/UserRatings.vue';
import { TESTIMONIALS_CATEGORY_ID, FAQ_CATEGORY_ID } from '@/utils/constants';

const store = useStore();
const router = useRouter();
const { t } = useTranslation(['shared', 'page-adapter']);
const active = ref(0);
const activeStep = ref(1);
const loading = computed(() => store.state.loading);
const sectionHowItWorksOffset = ref(null);
const sectionMobileAppAndAdapterOffset = ref(null);
const reducedConstant = ref(400);
const isMobile = computed(() => store.state.device.isMobile);

const adapter = computed(() => store.state.pages?.adapter);
const prices = computed(() => store.state.prices?.prices);
const adapterFaqCategory = FAQ_CATEGORY_ID.ADAPTERS_PAGE;
const adapterFaq = computed(() => store.state.faq?.faq[adapterFaqCategory - 1]);
const adapterTestimonials = computed(
    () =>
        store.state.testimonials?.testimonials[
            TESTIMONIALS_CATEGORY_ID.ADAPTERS_PAGE - 1
        ]
);
const langCode = computed(() => store.state.language.lang?.code);
const isInUK = computed(() => store.state.prices?.prices?.countryCode === 'GB');

const tagEvo = computed(() => store.state.campaign?.tags?.adaptersPageEvo);
const tagObd = computed(() => store.state.campaign?.tags?.adaptersPageObd);
const tagEvoUrl = computed(
    () => store.state.campaign?.tags?.adaptersPageEvoUrl
);
const tagObdUrl = computed(
    () => store.state.campaign?.tags?.adaptersPageObdUrl
);
const campaignPriceEvo = computed(() => store.state.campaign?.prices?.evo);
const campaignPriceObd = computed(() => store.state.campaign?.prices?.obd);

const changeActiveStep = ($event) => {
    activeStep.value = $event;
};

const checkForCampability = (brand) => {
    store.commit('vehiclebrands/wizard', {
        name: 'make',
        value: brand,
    });
    router.push({ name: 'supported-cars' });
};

const openVendorModal = (adapterType) => {
    const siteUrl =
        adapterType === 'evo'
            ? prices.value.vendors[0]?.evoSiteUrl
            : prices.value.vendors[0]?.siteUrl;
    const isEvo = adapterType === 'evo';

    if (prices?.value.vendors.length <= 1) {
        facebookGoogleAnalitycs('buy_now', {
            siteName: prices.value.vendors[0]?.siteName,
            siteUrl: siteUrl,
            adapterName: isEvo ? 'EVO' : 'OBD',
        });

        const shouldOpenNewTab = !/shop.+carista.com|2ly.link./.test(siteUrl);
        window.open(siteUrl, shouldOpenNewTab ? '_blank' : '_self' );
    } else {
        store.dispatch('modals/openModal', {
            currentModal: 'isVendorModalOpen',
            data: adapterType || true,
        });
    }
};

const handleAdapterScroll = () => {
    if (
        window.top.scrollY >
            sectionHowItWorksOffset.value - reducedConstant.value &&
        window.top.scrollY <
            sectionMobileAppAndAdapterOffset.value - reducedConstant.value
    ) {
        active.value = 5;
        calcActiveStep();
    } else if (
        window.top.scrollY >
        sectionMobileAppAndAdapterOffset.value - reducedConstant.value
    ) {
        active.value = 6;
    }
};

const calcOffsets = () => {
    if (!loading.value) {
        setTimeout(() => {
            // Select and get all sections offsets for visual animations
            const sectionHowItWorks = document.querySelector(
                '#adapterPageHowItWorks'
            );
            const mobileAppAndAdapter = document.querySelector('#appPricing');

            sectionHowItWorksOffset.value =
                sectionHowItWorks?.getBoundingClientRect().top + window.scrollY;
            sectionMobileAppAndAdapterOffset.value =
                mobileAppAndAdapter?.getBoundingClientRect().top +
                window.scrollY;

            // decrease the value for mobile devices to switch active state 200 pixels before section's offset top
            if (isMobile.value) reducedConstant.value = 200;

            window.addEventListener('scroll', handleAdapterScroll);
        }, 1000);
    }
};

const calcActiveStep = () => {
    const stepHeight =
        (sectionMobileAppAndAdapterOffset.value -
            sectionHowItWorksOffset.value) /
        3;
    if (window.top.scrollY < sectionHowItWorksOffset.value + stepHeight - 400) {
        activeStep.value = 1;
    } else if (
        window.top.scrollY > sectionHowItWorksOffset.value + stepHeight - 400 &&
        window.top.scrollY <
            sectionHowItWorksOffset.value + stepHeight * 2 - 700
    ) {
        activeStep.value = 2;
    } else if (
        window.top.scrollY >
        sectionHowItWorksOffset.value + stepHeight * 2 - 700
    ) {
        activeStep.value = 3;
    }
};

onBeforeMount(async () => {
    if (
        !adapterTestimonials.value ||
        (adapterTestimonials.value &&
            mapLangFullNameToCode(adapterTestimonials?.value[0].language) !==
                langCode.value)
    ) {
        await store.dispatch('testimonials/getTestimonials', {
            lang: langCode.value,
            categoryId: TESTIMONIALS_CATEGORY_ID.ADAPTERS_PAGE,
        });
    }

    if (
        !adapterFaq.value ||
        (adapterFaq.value &&
            mapLangFullNameToCode(adapterFaq?.value[0].language) !==
                langCode.value)
    ) {
        await store.dispatch('faq/getFaq', {
            lang: langCode.value,
            categoryId: adapterFaqCategory,
        });
    }
});

onMounted(() => {
    document.body.classList.remove('overflow-hidden');
    store.dispatch('vehiclebrands/getBrands');
    calcOffsets();
});

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleAdapterScroll);
});

watch(loading, (newVal) => {
    if (!newVal) {
        calcOffsets();
    }
});
watch(langCode, (newVal) => {
    store.dispatch('testimonials/getTestimonials', {
        lang: newVal,
        categoryId: TESTIMONIALS_CATEGORY_ID.ADAPTERS_PAGE,
    });
    store.dispatch('faq/getFaq', {
        lang: newVal,
        categoryId: adapterFaqCategory,
    });
});

useHead(() => {
    return createMetaInfo({
        title: adapter.value?.metaTitle,
        pageMeta: adapter.value,
    });
});
</script>

<style lang="scss" scoped>
.price-container {
    color: $color-white;
    font-size: 32px;
    font-weight: 500;
    margin: 20px 0 32px;

    display: flex;
    align-items: center;
    gap: 20px;

    &.obd {
        color: $color-black;
    }

    #original-price {
        font-size: 24px;
        color: $color-neutral-dark;
    }

    @media (max-width: $screen-md) {
        justify-content: center;
        text-align: center;
        margin: 0 0 50px 0;
        grid-row: 1;
    }
}

#original-price {
    font-size: 24px;
    color: $color-neutral-dark;
}

.sale-tag-container {
    margin: 0px auto;
}

.sale-tag-container-desktop {
    margin-bottom: 2.6rem;
}
</style>
