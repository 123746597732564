<template>
    <h6 class="social-header">{{ t('menus_socials') }}</h6>
    <ul class="social-icons-ul">
        <li
            @click="share('facebook', article?.slug)"
            :class="{ selected: selectedFb }"
        >
            <FacebookIcon :color="'#1B1E21'" class="social-icon" />
        </li>
        <li
            @click="share('linkedin', article?.slug)"
            :class="{ selected: selectedLi }"
        >
            <LinkedInIcon :color="'#1B1E21'" class="social-icon" />
        </li>
        <li
            @click="copyLink(article?.slug)"
            :class="{ selected: selectedCopy }"
        >
            <CopyIcon />
        </li>
    </ul>
</template>

<script setup>
import { ref } from 'vue';
import CopyIcon from '@/assets/icons/CopyIcon.vue';
import FacebookIcon from '@/assets/icons/FacebookIcon.vue';
import LinkedInIcon from '@/assets/icons/LinkedInIcon.vue';
import { copyBlogArticleLink } from '../../utils/helpers';
import { useTranslation } from 'i18next-vue';

const { t } = useTranslation(['menus']);

const props = defineProps({
    article: Object,
});

const selectedCopy = ref(false);
const selectedFb = ref(false);
const selectedLi = ref(false);

function copyLink(slug) {
    selectedCopy.value = true;
    copyBlogArticleLink(slug);
    setTimeout(() => {
        selectedCopy.value = false;
    }, 200);
}

function share(socialMedia, article) {
    if (socialMedia === 'facebook') selectedFb.value = true;
    if (socialMedia === 'linkedin') selectedLi.value = true;
    const baseUrl = process.env.VUE_APP_APP;

    setTimeout(() => {
        if (socialMedia === 'facebook') {
            selectedFb.value = false;
            window.open(
                `https://www.facebook.com/share.php?u=${baseUrl}/blog/${article}`,
                '_blank',
                'noreferrer'
            );
        }

        if (socialMedia === 'linkedin') {
            selectedLi.value = false;
            window.open(
                `https://www.linkedin.com/shareArticle?mini=true&url=${baseUrl}/blog/${article}`,
                '_blank',
                'noreferrer'
            );
        }
    }, 200);
}
</script>

<style lang="scss" scoped>
h6 {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 24px;

    @media (max-width: $screen-md) {
                font-size: 16px;
	margin-bottom: 18px;
    }
}

.social-icons-ul {
	display: flex;
	justify-content: space-between;
	max-width: 10rem;

    @media (max-width: $screen-md) {
	    max-width: 100%;
    }
}

li {
    width: 26px;

    :hover {
        cursor: pointer;
    }
}

.social-icon {
    height: 20px;
	width: 20px;
}
</style>
