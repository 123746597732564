import axios from "./http.service";
import { store } from "../store/store";

const langsMap = {
    en: 'en',
    de: 'de',
    es: 'es',
    fr: 'fr',
    it: 'it',
    ja: 'ja',
    ru: 'ru'
}
const langCode = store?.state?.language?.lang?.code
    ? langsMap[store.state.language.lang.code] : 'en';

class Blog {
    getInitialBlogData(page = 1, itemsPerPage = 9) {
        const query = {
        query: `
        {
            allArticles(input: {page: ${page}, perPage: ${itemsPerPage}, language: en}) {
                currentPage
                lastPage
                totalItems
                result {
                    message
                    status
                }
                articles {
                    createdAt
                    id
                    picture {
                        url
                    }
                    shortDescription
                    title
                    slug
                    tags {
                        id
                        name
                    }
                }
            }
            latestArticle(language: en) {
                result {
                    message
                    status
                }
                article {
                    createdAt
                    id
                    picture {
                        url
                    }
                    shortDescription
                    description
                    title
                    slug
                    tags {
                        id
                        name
                    }
                }
            }
            allTags(input: {page: 1, perPage: 99, language: ${langCode}}) {
                tags {
                    id
                    name
                }
                result {
                    message
                    status
                }
            }
            blogMetaFields {
                blogMetaDescription
                blogMetaTitle
                result {
                    message
                    status
                }
            }
        }`,
        };

        return axios
            .post(`${process.env.VUE_APP_WEB_CMS}`, query)
            .then((res) => res.data);
    }
    getAllArticles(page = 1, itemsPerPage = 9) {
        const query = {
        query: `
        {
            allArticles(input: {page: ${page}, perPage: ${itemsPerPage}, language: en}) {
                currentPage
                lastPage
                totalItems
                result {
                    message
                    status
                }
                articles {
                    createdAt
                    id
                    picture {
                        url
                        altText
                        title
                    }
                    shortDescription
                    title
                    slug
                    tags {
                        id
                        name
                    }
                }
            }
        }`,
        };

        return axios
            .post(`${process.env.VUE_APP_WEB_CMS}`, query)
            .then((res) => res.data);
    }
    getArticleById(id) {
        const query = {
            query: `
            {
                articleById(input: {articleId: ${id}, language: en}) {
                    result {
                      message
                      status
                    }
                    article {
                      createdAt
                      id
                      metaDescription
                      metaTitle
                      picture {
                        url
                        altText
                        title
                      }
                      description
                      slug
                      tags {
                        id
                        name
                      }
                      title
                      relatedArticles {
                        createdAt
                        picture {
                            url
                            altText
                            title
                        }
                        shortDescription
                        slug
                        tags {
                            id
                          name
                        }
                        title
                        id
                      }
                    }
                }
            }`,
            };

            return axios
                .post(`${process.env.VUE_APP_WEB_CMS}`, query)
                .then((res) => res.data);
    }
    getArticleBySlug(slug) {
        const query = {
            query: `
            {
                articleBySlug(input: {slug: "${slug}", language: en}) {
                    result {
                      message
                      status
                    }
                    article {
                      createdAt
                      id
                      metaDescription
                      metaTitle
                      picture {
                        url
                        altText
                        title
                      }
                      description
                      slug
                      tags {
                        name
                        id
                      }
                      title
                      relatedArticles {
                        createdAt
                        picture {
                            url
                            altText
                            title
                        }
                        shortDescription
                        slug
                        tags {
                            id
                          name
                        }
                        title
                        id
                      }
                    }
                }
            }`,
            };

            return axios
                .post(`${process.env.VUE_APP_WEB_CMS}`, query)
                .then((res) => res.data);
    }
    getArticlesByTag(tagId = 1, page = 1, itemsPerPage = 9) {
        const query = {
        query: `
        {
            articlesByTag(input: {tagId: ${tagId}, page: ${page}, perPage: ${itemsPerPage}, languageEnum: en}) {
                currentPage
                lastPage
                result {
                  message
                  status
                }
                totalItems
                articles {
                  createdAt
                  id
                  picture {
                    url
                    altText
                    title
                  }
                  shortDescription
                  slug
                  tags {
                    id
                    name
                  }
                  title
                }
            }
        }`,
        };

        return axios
            .post(`${process.env.VUE_APP_WEB_CMS}`, query)
            .then((res) => res.data);
    }
    getAllTags() {
        const query = {
        query: `
        {
            allTags(input: {page: 1, perPage: 99, language: ${langCode}}) {
                tags {
                    id
                    name
                }
                result {
                    message
                    status
                }
            }
        }`,
        };

        return axios
            .post(`${process.env.VUE_APP_WEB_CMS}`, query)
            .then((res) => res.data);
    }
}

export default new Blog();
