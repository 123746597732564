<template>
    <div class="table-of-content toc-sticky-wrapper-mobile">
        <h6
            @click="toggleTocDropdown"
            v-click-outside="handleOutsideClick"
            :class="{ clickable: isMobile }"
        >
            {{ t('page-blog_table-of-content') }}
            <ArrowIcon v-if="isMobile" :class="{ rotate: isDropdownOpen }" />
        </h6>
        <ul
            :class="[
                'toc-list',
                {
                    dropdown: isMobile,
                    open: isDropdownOpen && isMobile,
                },
            ]"
        >
            <li
                v-for="(article, index) in articleHeadings"
                :key="article.id"
                class="toc-list-item"
            >
                <a
                    @click="onTocItemClick(article.id)"
                    :class="{ active: isTocItemActive(article.id, index) }"
                    class="toc-list-item-link"
                >
                    {{ article.h2 }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from 'i18next-vue';
import ArrowIcon from '@/assets/icons/ArrowIcon.vue';

const props = defineProps({
    articleHeadings: {
        type: Array,
        required: true,
    },
    activeSectionId: {
        type: String,
        default: '',
    },
});

const emits = defineEmits(['toc-item-click']);

const { t } = useTranslation(['page-blog']);

const store = useStore();

const isMobile = computed(() => store.state.device.isMobile);

const isDropdownOpen = ref(false);

function toggleTocDropdown() {
    if (isMobile.value) {
        isDropdownOpen.value = !isDropdownOpen.value;
    }
}

function handleOutsideClick() {
    if (isMobile.value) {
        isDropdownOpen.value = false;
    }
}

function onTocItemClick(elementId) {
    emits('toc-item-click', elementId);
    isDropdownOpen.value = false;
}

function isTocItemActive(articleId, itemIndex) {
    if (itemIndex == 0 && !props.activeSectionId) {
        return true;
    }

    return articleId == props.activeSectionId;
}
</script>
