import { GET_CAMPAIGN_BY_COUNTRY_CODE } from '@/utils/queries';
import i18next from 'i18next';
import axios from 'axios';
import { mapLangCodeToFullName } from '@/utils/helpers';

const state = () => ({
    ribbon: null,
    popUp: null,
    prices: null,
    tags: null,
    meta: null,
    campaign: null,
});

const mutations = {
    SET_CAMPAIGN_DATA(state, data) {
        // Ribbon data
        state.bar = {
            text: data.ribbonDesktop,
            textMobile: data.ribbonMobile,
            tag: data.ribbonTagDesktop,
            tagMobile: data.ribbonTagMobile,
            url: data.ribbonUrl,
            isAnimated: data.ribbonAnimation,
        };

        // Pop up date
        state.popUp = {
            title: data.popUpTitleDesktop,
            titleMobile: data.popUpTitleMobile,
            subtitle: data.popUpSubtitleDesktop,
            subtitleMobile: data.popUpSubtitleMobile,
            tag: data.popUpTagDesktop,
            tagMobile: data.popUpTagMobile,
            button: data.popUpButtonDesktop,
            buttonMobile: data.popUpButtonMobile,
            buttonUrl: data.popUpButtonUrl,
        };

        // Tag data
        state.tags = {
            footer: data.footerTag,
            header: data.menuTag,
            headerAndFooterUrl: data.menuTagAndFooterTagUrl,
            adaptersPageEvo: data.adaptersPageEvoTag,
            adaptersPageEvoUrl: data.adaptersPageEvoTagUrl,
            adaptersPageObd: data.adaptersPageCaristaObdTag,
            adaptersPageObdUrl: data.adaptersPageCaristaObdTagUrl,
            appAndPricingEvo: data.appAndPricingPagesEvoTag,
            appAndPricingObd: data.appAndPricingPagesCaristaObdTag,
            appAndPricingEvoUrl: data.appAndPricingPagesEvoTagUrl,
            appAndPricingObdUrl: data.appAndPricingPagesCaristaObdTagUrl,
        };

        // Prices data
        state.prices = {
            evo: data.promoPriceEvo,
            obd: data.promoPriceCaristaObd,
        };

        // Meta data
        state.meta = {
            active: data.active,
            timeRemaining: data.remainingTime,
            secondsRemaining: data.remainingTimeSeconds,
            campaignEnd: data.endTime
        };

        // All at once
        state.campaign = data;
    },
};

const actions = {
    async fetchCampaign({ commit, rootState: { geoIP } }) {
        try {
            const currentLanguage = mapLangCodeToFullName(i18next.language);
            const country = geoIP.geoIP.countryCode;

            const { data } = await axios.post(
                `${process.env.VUE_APP_WEB_CMS}`,
                {
                    query: GET_CAMPAIGN_BY_COUNTRY_CODE(
                        country,
                        currentLanguage,
                        process.env.VUE_APP_WEB_CMS_ENV
                    ),
                }
            );

            const campaignData = data.data.campaignByCountryCode.campaign;

            if (!campaignData) {
                return;
            }

            commit(
                'SET_CAMPAIGN_DATA',
                campaignData
            );
        } catch (error) {
            console.error('Error fetching campaign data:', error);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
