import { createStore } from 'vuex';
import auth from './auth';
import language from './language';
import profile from './profile';
import vehiclebrands from './vehiclebrands';
import modals from './modals';
import faq from './faq';
import prices from './prices';
import testimonials from './testimonials';
import pages from './pages';
import blog from './blog';
import geoIP from './geoIP';
import device from './device';
import axios from 'axios';
import campaign from './campaign';

// import Auth from "../services/auth.service";
import { GET_DISTRIBUTOR_BY_UUID } from '@/utils/queries';

export const store = createStore({
    modules: {
        auth,
        language,
        profile,
        vehiclebrands,
        faq,
        modals,
        prices,
        testimonials,
        pages,
        blog,
        geoIP,
        device,
        campaign,
    },
    state: {
        loading: true,
        error: null,
        distributorData: null,
        allowVendorsModalEventEmit: true,
    },
    mutations: {
        loading(state, payload) {
            state.loading = payload;
        },
        setAllowVendorsModalEvent(state, payload) {
            state.allowVendorsModalEventEmit = payload;
        },
        SET_DISTRIBUTOR_DATA(state, payload) {
            state.distributorData = payload;
        },
        SET_LOADING(state, isLoading) {
          state.loading = isLoading;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
    },
    actions: {
        async init({ commit, dispatch, rootState: { geoIP } }) {
            await dispatch('geoIP/getIP');
            const countryCode = geoIP?.geoIP?.countryCode;

            dispatch('prices/getPrices', countryCode);
            dispatch("campaign/fetchCampaign");

            const pages = dispatch('pages/getPages');

            Promise.all([pages]).then(() => {
                commit('loading', false);
            });

            // Auth.isAuthenticated();
        },
        resetModalStates({ dispatch }) {
            dispatch('modals/resetAll');
        },
        resetState({ dispatch }) {
            // dispatch("profile/resetState");
            // dispatch("auth/resetState");
        },
        changeLang({ dispatch }, lang) {
            dispatch('language/changeLanguage', lang);
        },
        setAllowVendorsModalEvent({ commit }, payload) {
            commit('setAllowVendorsModalEvent', payload);
        },
        async fetchDistributorByUuid({ commit }, distributorUuid) {
            commit('SET_LOADING', true);
            commit('SET_ERROR', null);

            try {
                const { data } = await axios.post(`${process.env.VUE_APP_WEB_CMS}`, {
                    query: GET_DISTRIBUTOR_BY_UUID(),
                    variables: { uuid: distributorUuid },
                });

                commit(
                    'SET_DISTRIBUTOR_DATA',
                    data?.data?.distributorByUuid?.distributor
                );
            } catch (error) {
                console.error('Error fetching distributor data:', error);
                commit('SET_ERROR', error);
            } finally {
                commit('SET_LOADING', false);
            }
        },
    },
    getters: {},
});
