import { createApp } from 'vue';
import "./styles/app.scss";

import "es6-promise";

import { createHead } from '@unhead/vue';
import VueScrollTo from "vue-scrollto";
import { VueProgressBar } from "@aacassandra/vue3-progressbar";

import App from "./App.vue";
import router from "./config/router.config";
import { store } from "./store/store";
import init from './config/i18n.config';
import progressbarOptions from "./config/progressbar.config";
import Vue3Lottie from "vue3-lottie";
import appconfig from "./config/app.config";
import { createGtm } from '@gtm-support/vue-gtm';
import clickOutside from './utils/click-outside';

const app = createApp(App);

const head = createHead()

// Global Properties
app.config.globalProperties.$appConfig = appconfig;

// Use Plugins
app.use(head);
app.use(VueScrollTo);
app.use(VueProgressBar, progressbarOptions);
app.use(Vue3Lottie);

app.use(router);
app.use(store);

app.use(
    createGtm({
      id: "GTM-WQMQML3",
      vueRouter: router
    })
  );

  app.directive('click-outside', clickOutside);

if (process.env.NODE_ENV !== 'production') {
    app.config.devtools = true;
}

// i18n init
init(app);

app.mount("#app");

export default app;
