<template>
    <div
    v-if="text"
        :style="{
            'border-radius': borderRadius + 'px',
        }"
        :class="{
            reverse: reverseColors,
        }"
        class="sale-tag"
    >
        {{ text }}
    </div>
</template>

<script setup>
import { useTranslation } from 'i18next-vue';

const { t } = useTranslation(['shared']);

const props = defineProps({
    text: String,
    borderRadius: {
        type: Number,
        default: 26,
    },
    reverseColors: {
        type: Boolean,
        default: false,
    },
});
</script>

<style lang="scss" scoped>
.sale-tag {
    display: inline-block;
    color: $color-white;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 600;
    background: $bg-sale-red;
    width: fit-content;

    &.reverse {
        color: $color-sale-red;
        background: $color-white;
    }
}
</style>
