<template>
    <Transition v-if="isModalOpened && $appConfig.ENABLE_CAMPAIGNS" name="fade-slide">
        <div class="modal-wrapper-dark-background" @click.self="close">
            <div class="sale-modal">
                <div class="sale-modal__description">
                    <button name="close-btn" class="close-btn" @click="close">
                        <XCloseIcon />
                    </button>
                    <SaleTag :text="popUp.tagText" />
                    <h2>
                        {{ popUp.title }}
                    </h2>
                    <h4 style="margin-top: -10px; font-size: 20px">
                        {{ popUp.subtitle }}
                    </h4>
                    <a
                        v-if="popUp.buttonUrl && popUp.button"
                        @click="getNow"
                        :href="popUp.buttonUrl"
                        class="btn-feedback-outlined"
                        target="_blank"
                    >
                        {{ popUp.button }}
                        <ArrowRightIcon />
                    </a>
                    <a
                        href="javascript:void(0);"
                        @click="close"
                        style="
                            text-align: center;
                            font-weight: 600;
                            color: #3973b9;
                            font-size: 16px;
                        "
                        >{{ t('shared_close') }}
                    </a>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import XCloseIcon from '@/assets/icons/XCloseIcon';
import ArrowRightIcon from '@/assets/icons/supportedCars/ArrowRightIcon';
import { useTranslation } from 'i18next-vue';
import SaleTag from '../SaleTag.vue';
import { facebookGoogleAnalitycs } from '@/utils/helpers';

const { t } = useTranslation(['shared', 'translation']);

const store = useStore();
const isMobile = computed(() => store.state.device.isMobile);
const isModalOpened = computed(() => store.state.modals?.isSaleModalOpen && store.state.campaign?.popUp?.title);

const popUp = computed(() => {
    const content = store.state.campaign?.popUp ?? {};
    const isMobileDevice = isMobile.value;

    return {
        title: isMobileDevice ? (content.titleMobile ?? content.title) : content.title,
        subtitle: isMobileDevice ? (content.subtitleMobile ?? content.subtitle) : content.subtitle,
        tagText: isMobileDevice ? (content.tagMobile ?? content.tag) : content.tag,
        button: isMobileDevice ? (content.buttonMobile ?? content.button) : content.button,
        buttonUrl: content.buttonUrl,
    };
});

function close() {
    facebookGoogleAnalitycs('get_now_close_button');

    store.dispatch('resetModalStates');
}

function getNow() {
    facebookGoogleAnalitycs('get_now');

    store.dispatch('resetModalStates');
}
</script>

<style lang="scss" scoped>
h2 {
    font-size: 32px;
    font-weight: 500;

    @media (max-width: $screen-md) {
        font-size: 24px;
    }
}
</style>
